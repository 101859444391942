import React, { useCallback, useState, useEffect } from 'react';
import PatientFolder from 'components/patient-folder/PatientFolder';
import Overlay from 'components/overlay/Overlay';
import Http from 'components/administrator/http';

// import RoomMonitory from 'components/administrator/RoomMonitoring';
import robot from 'assets/img/admin/robot-cut.svg';
import folder from 'assets/svg/folder.svg';
import { ADMIN, STEPS } from 'Constants';
import svgTimer from 'assets/svg/timer.svg';
import scss from './control-panel.module.scss';

interface ControlPanelProps {
  onRobotSpeechStarted: () => void;
  onSetTimer: (value: number) => void;
  onGameStarted: () => void;
  timer: number;
  event: string | null;
  IO: any;
  flowStep: string;
  reset: boolean;
}

const ControlPanel: React.FC<ControlPanelProps> = ({
  event,
  onGameStarted,
  onSetTimer,
  timer,
  onRobotSpeechStarted,
  IO,
  flowStep,
  reset,
}) => {
  const [page, setPage] = useState<number>(1);
  const [folderOpened, setFolderOpened] = useState<boolean>(false);
  const [robotSpeechStarted, setRobotSpeechStarted] = useState<boolean>(false);

  const onPrev = useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page]);

  const onNext = useCallback(() => {
    if (page < 2) {
      setPage(page + 1);
    }
  }, [page]);

  useEffect(() => {
    if (reset === true) {
      /*
      setPage(1);
      setFolderOpened(false);
      setRobotSpeechStarted(false);
      */
    }
  }, [reset]);

  /*
  useEffect(() => {
    console.log('folderOpened', folderOpened);
  }, [folderOpened]);
  */

  useEffect(() => {
    if (STEPS.indexOf(flowStep) === STEPS.indexOf('START')) {
      // onNext();
      // setPage(2);
    } else if (flowStep === 'OPEN_FOLDER') {
      setPage(2);
      setFolderOpened(true);
    }
  }, [flowStep]);

  const onRobotSpeechStart = useCallback(() => {
    if (!robotSpeechStarted) {
      Http.post('/start', { event }).then(() => {
        setRobotSpeechStarted(true);
        onRobotSpeechStarted();
      });
    }
  }, [robotSpeechStarted]);

  const onStartGame = useCallback(() => {
    Http.post('/start/game', { event }).then(() => {
      onGameStarted();
    });
  }, []);

  const openFolder = useCallback(() => {
    Http.post('/open/folder', { event }).then(() => {
      // IO.emit('step-in', 'OPEN_FOLDER');
      setFolderOpened(true);
    });
  }, []);

  /*
  const closeFolder = useCallback(() => {
    Http.post('/close/folder', { event }).then(() => {
      setFolderOpened(false);
    });
  }, []);
  */

  const getTime = useCallback((seconds: number) => {
    const min = Math.floor(seconds / 60);
    let sec = seconds - min * 60;
    if (sec < 0) {
      sec = 0;
    }
    return `${`0${min}`.slice(-2)}:${`0${sec}`.slice(-2)}`;
  }, []);

  return (
    <div className={scss.controlPanel}>
      {page === 1 && (
        <>
          <div>
            <h3>Starten Sie das Einführungsvideo</h3>
          </div>
          <div>
            <div className="robot-button" role="button" tabIndex={0} onClick={onRobotSpeechStart}>
              {/* <img alt="" src={robot} /> */}
              <div className="img" style={{ backgroundImage: `url(${robot})` }} />
              <p style={{ fontFamily: 'Jost-Bold' }}>Einführung starten</p>
            </div>
            <div>
              <p>Starten Sie die Einführung für das Vtopia Medical Training Center</p>
            </div>
            <div className="timer">
              <img alt="" src={svgTimer} className="folder" />
              {getTime(timer)}
            </div>
          </div>
        </>
      )}
      {page === 2 && !folderOpened && (
        <>
          <div>
            <h3>Stellen sie den patientenfall vor.</h3>
          </div>
          <div>
            <div
              className="patient-case-button patient-case-resizer"
              style={{ cursor: 'pointer' }}
              role="button"
              tabIndex={0}
              onClick={openFolder}
            >
              <img alt="" src={folder} className="folder" />
              <p className="smaller" style={{ fontFamily: 'Jost-Bold' }}>
                <span className="taller">P</span>atientenfall
              </p>
            </div>
            <div>
              <p>Patientenfall öffnen</p>
            </div>
            <div />
          </div>
        </>
      )}
      {page === 2 && folderOpened === true && (
        <>
          <div className="patient-folder-container">
            <Overlay closeOverlay={() => {}}>
              <PatientFolder />
            </Overlay>
          </div>
        </>
      )}
      <div className="navigate border">
        <div style={{ display: 'none' }} />
        {/*
          <div
          style={{
            opacity: timer === 0 && !(page === 2 && !folderOpened) ? '1' : '0.5',
            cursor: timer === 0 && !(page === 2 && !folderOpened) ? 'pointer' : 'not-allowed',
          }}
        />
        */}
      </div>
      <div className="navigate">
        <button className="previous" type="button" />
        <button
          className={`next ${page < 2 ? 'next-1' : 'next-2'}`}
          style={{
            opacity: timer === 0 && !(page === 2 && !folderOpened) ? '1' : '0.5',
            cursor: timer === 0 && !(page === 2 && !folderOpened) ? 'pointer' : 'not-allowed',
          }}
          type="button"
          onClick={() => {
            if (timer === 0 && !(page === 2 && !folderOpened)) {
              if (page > 1) {
                onStartGame();
                onSetTimer(ADMIN.waiting_room_time);
              } else {
                onNext();
              }
            }
          }}
        >
          {page < 2 && (
            <span>
              <i>Z</i>UM&nbsp;PATIENTENFALL
            </span>
          )}
          {page >= 2 && (
            <span style={{ textTransform: 'uppercase' }}>
              <i>L</i>abor&nbsp;freigeben
            </span>
          )}
        </button>
      </div>
    </div>
  );
};

export default ControlPanel;
