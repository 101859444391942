import React, { useState, useEffect, useCallback } from 'react';
import { ADMIN, STEPS } from 'Constants';
import Http from 'components/administrator/http';
import folder from 'assets/svg/folder.svg';
import eye from 'assets/svg/eye.svg';
import svgTimer from 'assets/svg/timer.svg';
// import concept from 'assets/img/speech/concept.jpg';
import PatientFolder from 'components/patient-folder/PatientFolder';
import Overlay from 'components/overlay/Overlay';
import scss from './navigator.module.scss';

interface NavigatorProps {
  enabled: boolean;
  onNavigate: (e: any) => void;
  onSetTimer: (v: number) => void;
  onMonitoringStep: (e: string) => void;
  onEyeLayer: (e: boolean) => void;
  onReset: () => void;
  onSessionReset: () => void;
  IO: any;
  timer: number;
  event: string | null;
  monitoringStep: string;
  flowStep: string;
  reset: boolean;
}

const Navigator: React.FC<NavigatorProps> = ({
  onNavigate,
  onSetTimer,
  onMonitoringStep,
  onEyeLayer,
  onReset,
  onSessionReset,
  event,
  enabled,
  IO,
  timer,
  monitoringStep,
  flowStep,
  reset,
}) => {
  const [currentRoom, setCurrentRoom] = useState<string>('');
  const [folderOpened, setFolderOpened] = useState<boolean>(false);
  const [inQuestionnary, setInQuestionnary] = useState<boolean>(false);

  useEffect(() => {
    if (reset === true) {
      /*
      setCurrentRoom('');
      setFolderOpened(false);
      setInQuestionnary(false);
      */
    }
  }, [reset]);

  const onNextRoom = useCallback(() => {
    Http.post('/next/room', { event }).then((data: any) => {
      if (data.success === true) {
        onMonitoringStep('room');
      }
    });
  }, []);

  const onNextQuestionnary = useCallback(() => {
    Http.post('/next/questionnary', { event }).then((data: any) => {
      if (data.success === true) {
        onMonitoringStep('questionnary');
      }
    });
  }, []);

  /*
  const onEnd = useCallback(() => {
    Http.post('/end', { event }).then((data: any) => {
      if (data.success === true) {
        onReset();
      }
    });
  }, []);
  */

  useEffect(() => {
    if (monitoringStep === 'questionnary') {
      setInQuestionnary(true);
    } else {
      setInQuestionnary(false);
    }
  }, [monitoringStep]);

  // Todo refactor
  useEffect(() => {
    let isMounted = true;
    if (IO) {
      IO.on('users', (data: string) => {
        if (data === 'start-game') {
          if (isMounted) {
            setCurrentRoom('0');
            onNavigate(1);
          }
        }
      });
      IO.on('next-room', (data: string) => {
        const roomId = +data;
        if (roomId < 3 && isMounted) {
          setCurrentRoom(data);
          onNavigate(roomId + 1);
        }
      });
      IO.on('activate-next-room', (data: string) => {
        onSetTimer(0);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [IO]);

  const getTime = useCallback((seconds: number) => {
    const min = Math.floor(seconds / 60);
    let sec = seconds - min * 60;
    if (sec < 0) {
      sec = 0;
    }
    return (
      <>
        <p className="smaller">
          <span className="taller">{`${`0${min}`.slice(-2)}`}</span>
          <span>{`:${`0${sec}`.slice(-2)}`}</span>
        </p>
      </>
    );
  }, []);

  useEffect(() => {
    if (flowStep) {
      if (flowStep === 'OPEN_FOLDER') {
        // setFolderOpened(true);
      } else if (flowStep.indexOf('ROOM') !== -1) {
        const last = flowStep[flowStep.length - 1];
        setCurrentRoom(last);
      } else if (flowStep.indexOf('QUESTIONNARY') !== -1) {
        const last = `${+flowStep[flowStep.length - 1] - 1}`;
        setCurrentRoom(last);
      }
    }
  }, [flowStep]);

  return (
    <>
      <div className={scss.navigator}>
        <hr className="footer-line" />
        <div>
          <div className={`room-tab ${currentRoom === '' ? 'active' : !enabled ? 'disabled' : ''}`}>
            Einführung
          </div>
        </div>
        <div>
          <div
            className={`room-tab  ${currentRoom === '0' ? 'active' : !enabled ? 'disabled' : ''}`}
          >
            Raum 1
          </div>
        </div>
        <div>
          <div
            className={`room-tab ${currentRoom === '1' ? 'active' : !enabled ? 'disabled' : ''}`}
          >
            Raum 2
          </div>
        </div>
        <div>
          <div
            className={`room-tab ${currentRoom === '2' ? 'active' : !enabled ? 'disabled' : ''}`}
          >
            Raum 3
          </div>
        </div>
        {folderOpened && (
          <>
            <div className="patient-folder-container">
              <Overlay
                closeOverlay={() => {
                  setFolderOpened(false);
                }}
              >
                <PatientFolder />
              </Overlay>
            </div>
          </>
        )}
        {currentRoom !== '' && (
          <>
            <div className="tools">
              {!inQuestionnary && (
                <>
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      onEyeLayer(true);
                    }}
                  >
                    <img
                      alt=""
                      style={{ cursor: 'pointer', width: '70px', marginLeft: 'calc(50% - 35px)' }}
                      src={eye}
                      className="folder"
                    />
                    <p className="smaller">
                      <span className="taller">R</span>AUMANSICHT
                    </p>
                  </div>
                  <div>
                    <img alt="" src={svgTimer} className="folder" />
                    {getTime(timer)}
                  </div>
                </>
              )}
              <div
                style={{ cursor: 'pointer' }}
                role="button"
                tabIndex={0}
                onClick={() => {
                  setFolderOpened(true);
                }}
              >
                <img alt="" src={folder} className="folder" />
                <p className="smaller">
                  <span className="taller">P</span>ATIENTENFALL
                </p>
              </div>
            </div>
            {/*
            <div className="navigate border">
              <div style={{ display: 'none' }} />
              <div
                style={{
                  opacity: timer === 0 ? '1' : '0.5',
                  cursor: timer === 0 ? 'pointer' : 'not-allowed',
                }}
              />
            </div>
            */}
            <div className="navigate">
              <button className="previous" type="button" />
              <button
                className={`next ${inQuestionnary ? 'next-3' : 'next-1'}`}
                style={{
                  opacity: timer === 0 ? '1' : '0.5',
                  cursor: timer === 0 ? 'pointer' : 'not-allowed',
                }}
                type="button"
                onClick={() => {
                  if (timer === 0 && inQuestionnary && currentRoom === '2') {
                    // onEnd();
                    onSessionReset();
                  } else {
                    if (timer === 0 && !inQuestionnary) {
                      onNextQuestionnary();
                      onSetTimer(ADMIN.waiting_questionnary_time);
                    }
                    if (timer === 0 && inQuestionnary) {
                      onNextRoom();
                      onSetTimer(ADMIN.waiting_room_time);
                    }
                  }
                }}
              >
                {/*! inQuestionnary && currentRoom === '0' && (
                  <span>
                    <i>L</i>ABOR STARTEN
                  </span>
                ) */}
                {inQuestionnary && currentRoom === '0' && (
                  <span style={{ textTransform: 'uppercase' }}>
                    <i>I</i>NFUSIONSRAUM freigeben
                  </span>
                )}
                {inQuestionnary && currentRoom === '1' && (
                  <span style={{ textTransform: 'uppercase' }}>
                    <i>W</i>OHNUNG freigeben
                  </span>
                )}
                {inQuestionnary && currentRoom === '2' && (
                  <span style={{ textTransform: 'uppercase' }}>
                    <i>W</i>eiter&nbsp;zur diskussion
                  </span>
                )}
                {!inQuestionnary && (
                  <span style={{ textTransform: 'uppercase' }}>
                    <i>F</i>ragebogen starten
                  </span>
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Navigator;
