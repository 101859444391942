import React, { useEffect, useReducer, useState, useCallback } from 'react';
import 'App.scss';
import 'i18n/i18n';
import Game from 'components/game/Game';
import Speech from 'components/speech/Speech';
import reducer, { initialState, VectibixContext } from 'context/vectibix-context';
import Administrator from 'components/administrator/Adminstrator';
import Http from 'components/administrator/http';
import io from 'socket.io-client';
import logo from 'assets/img/logo.png';
import Animation from 'components/speech/Animation';
import scss from './components/administrator/administrator.module.scss';

const App: React.FC = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isAdmin, setAdmin] = useState<null | boolean>(null);
  // const [logOut, setLogOut] = useState<null | boolean>(null);
  const { location } = props;
  const user: string | null = new URLSearchParams(location.search).get('user');
  const isadmin = new URLSearchParams(location.search).get('isadmin');
  // const logout = new URLSearchParams(location.search).get('logout');
  const event: string | null = new URLSearchParams(location.search).get('event');
  const [IO, setIO] = useState<null | any>(null);
  // const [socket, setSocket] = useState<null | any>(null);
  const [IOConnected, setIOConnected] = useState(false);
  const [started, setStarted] = useState<boolean>(false);
  const [fadeOutOn, setFadeOutOn] = useState<boolean>(false);
  const [animationStarted, setAnimationStarted] = useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);
  // const [reset, setReset] = useState<boolean>(false);

  const onGameReset = useCallback(() => {
    // setAdmin(null);
    // setLogOut(null);
    // setIOConnected(false);
    setAnimationStarted(false);
    setFadeOutOn(false);
    setStarted(false);
    setReset(false);
  }, []);

  useEffect(() => {
    const opt: any = { query: `isadmin=${isadmin}&event=${event}&user=${user}` };
    setIO(io(`${process.env.REACT_APP_SERVER_URL}`, opt));
  }, []);

  /*
  useEffect(() => {
    if (socket && isAdmin === false) {
      socket.emit('step-in', 'CONNECT');
    }
  }, [socket, isAdmin]);
  */

  useEffect(() => {
    if (IO) {
      if (!IOConnected) {
        IO.connect();
        console.log('re/connect');
      }
      IO.on('connect', () => {
        setIOConnected(IO.connected);
        IO.emit('step-in', 'CONNECT');
        console.log('step CONNECT');
        console.log('on connect');
      });
      IO.on('disconnect', () => {
        setIOConnected(IO.connected);
        console.log('on disconnect');
      });
      if (!isAdmin) {
        /*
        if (sessionStorage.getItem('started') === 'true') {
          setStarted(true);
        }
        */
        IO.on('users', (data: any) => {
          if (data === 'start') {
            setFadeOutOn(true);
            const id = setTimeout(() => {
              setStarted(true);
              clearTimeout(id);
              if (isAdmin === false) {
                IO.emit('step-in', 'START');
                console.log('step START');
              }
            }, 1500);
            // sessionStorage.setItem('started', 'true');
          }
        });
      }
    }
  }, [IO, IOConnected, isAdmin /* , socket */]);

  useEffect(() => {
    let id: any;
    if (reset) {
      id = setTimeout(() => {
        onGameReset();
        clearTimeout(id);
      }, 500);
    }
    return () => {
      clearTimeout(id);
    };
  }, [reset]);

  useEffect(() => {
    if (IO) {
      IO.on('logout', () => {
        setReset(true);
      });
    }
  }, [IO, setReset]);

  useEffect(() => {
    if (IO && IOConnected) {
      if (isadmin === 'true') {
        Http.post('/login/admin', {
          event,
        }).then((res) => {
          if (res.success === true) {
            setAdmin(true);
          } else {
            setAdmin(false);
          }
        });
      } else if (user) {
        setAdmin(false);
        IO.on('start', (value: any) => {
          if (value === 'login') {
            Http.post('/login', {
              event,
              user,
            }).then((data: any) => {
              IO.emit('step-in', 'START_LOGIN');
              console.log('step START LOGIN');
              setAnimationStarted(true); //
              if (data.started === true) {
                setStarted(true);
              }
            });
          }
        });
      }
    }
  }, [isadmin, user, IO, IOConnected]);

  const onAnimationDone = useCallback(() => {
    // setAnimationStarted(false);
  }, []);

  return (
    <React.StrictMode>
      {/* logOut && <span>Logout!</span> */}
      {/* logOut === null && */ isAdmin === true && <Administrator event={event} IO={IO} />}
      {
        /* logOut === null && */ isAdmin === false && (
          <VectibixContext.Provider
            value={{
              state,
              dispatch,
            }}
          >
            <div className="App">
              {!started && !animationStarted && (
                <div className={`${scss.start} ${fadeOutOn ? 'fadeout' : ''}`}>
                  <img src={logo} alt="" />
                  <h1>
                    <span style={{ color: '#FCB50C', fontSize: '40px' }}>V</span>TOPIA MEDICAL
                    TRAINING CENTER{' '}
                    {/* animationStarted ? (
                      <b style={{ position: 'fixed', top: 0, right: 0, color: 'black' }}>
                        ANISTART
                      </b>
                    ) : (
                      <b>ANINOTSTARTED</b>
                    )}{' '}
                    {started ? (
                      <b style={{ position: 'fixed', top: '30px', right: 0, color: 'black' }}>
                        STARTED
                      </b>
                    ) : (
                      <b>NOTSTARTED</b>
                    ) */}
                  </h1>
                  <h2>EIN FALL FÜR VECTIBIX</h2>
                </div>
              )}
              {!started && animationStarted && (
                <Animation
                  reset={reset}
                  onGameReset={onGameReset}
                  onAnimationDone={onAnimationDone}
                />
              )}
              {started && (
                <Speech isAdmin={isAdmin} reset={reset} onGameReset={onGameReset} IO={IO}>
                  <Game
                    onGameReset={onGameReset}
                    event={event}
                    user={user}
                    IO={IO}
                    isAdmin={isAdmin}
                    reset={reset}
                  />
                </Speech>
              )}
            </div>
          </VectibixContext.Provider>
        )
      }
    </React.StrictMode>
  );
};

export default App;
