import React, { useContext } from 'react';
import { VectibixContext } from 'context/vectibix-context';
import { useTranslation } from 'react-i18next';

import 'components/inventory/Inventory.scss';
import { CLUES } from 'Constants';

interface InventoryProps {}

export const Inventory: React.FC<InventoryProps> = () => {
  const [t] = useTranslation('common');
  const {
    state: { clues, currentRoom },
    dispatch,
  } = useContext(VectibixContext);
  return (
    <>
      <h2>{t('Objects')}</h2>
      <div className="inventory">
        {clues[currentRoom] &&
          clues[currentRoom].map((key: string) => (
            <img key={key} src={CLUES[key][0]} alt="" className="clue" />
          ))}
      </div>
    </>
  );
};

export default Inventory;
