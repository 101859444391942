export function vh(v: number): string {
  return `calc(var(--vh, 1vh) * ${v})`;
}

export function vw(v: number): string {
  return `${v}vw`;
}

export function vhCalc(v: number): number {
  const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  return (v * h) / 100;
}

export function vwCalc(v: number): number {
  const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  return (v * w) / 100;
}

export function vmin(v: number): number {
  return Math.min(vhCalc(v), vwCalc(v));
}

export function vmax(v: number): number {
  return Math.max(vhCalc(v), vwCalc(v));
}

export function max(a: number, b: number): number {
  return a > b ? a : b;
}

export function min(a: number, b: number): number {
  return a < b ? a : b;
}

export function formatTime(secs: number): { minutes: string; seconds: string } {
  const minutes = (secs / 60) | 0;
  const seconds = secs - minutes * 60;
  return {
    minutes: minutes.toString(),
    seconds: seconds < 10 ? `0${seconds}` : seconds.toString(),
  };
}
