import React, { useContext } from 'react';
import Password from 'validations/password';
import { VectibixContext } from 'context/vectibix-context';

interface ClueValidatorProps {
  id: string;
  onValidate: (e: any) => void;
}

const ClueValidator: React.FC<ClueValidatorProps> = ({ id, onValidate }) => {
  const {
    state: { currentRoom },
  } = useContext(VectibixContext);
  return (
    <>{`${currentRoom}-${id}` === 'laboratory-password' && <Password onValidate={onValidate} />}</>
  );
};

export default ClueValidator;
