import React, { useCallback, useContext, useEffect, useState } from 'react';
import Room from 'components/Room/Room';
import Toolbar from 'components/toolbar/Toolbar';
import { VectibixContext } from 'context/vectibix-context';
import { SCENES, SCENES_DATA } from 'Constants';
import RoomIntro from 'components/room-intro/RoomIntro';
import Quizz from 'components/quizz/Quizz';
import Overlay from 'components/overlay/Overlay';
import Inventory from 'components/inventory/Inventory';
import PatientFolder from 'components/patient-folder/PatientFolder';
import GameOver from 'components/game-over/GameOver';
import References from 'components/references/References';
import Http from 'components/administrator/http';
import diffVirt1 from 'assets/img/admin/diff-virt1.png';
import diffVirt2 from 'assets/img/admin/diff-virt2.png';
import diffVirt3 from 'assets/img/admin/diff-virt3.png';
import scss from './game.module.scss';

interface GameProps {
  event: string | null;
  user: string | null;
  IO: any;
  isAdmin: boolean;
  reset: boolean;
  onGameReset: () => void;
}
interface UpdatableNumber {
  value: number;
  time: number;
}

type GameStep =
  | 'intro'
  | 'room'
  | 'quizz'
  | 'game-over'
  | 'waiting'
  | 'patient-folder'
  | 'clues-details';
type OverlayType = 'patient-folder' | 'inventory' | 'references' | null;

export const Game: React.FC<GameProps> = ({ event, user, IO, isAdmin, reset, onGameReset }) => {
  const {
    state: { currentRoom, clues },
    dispatch,
  } = useContext(VectibixContext);
  const foundClues: string[] = clues[currentRoom] || [];
  const [timer, setTimer] = useState<number>(currentRoom ? SCENES_DATA[currentRoom].time : 0);
  const [step, setStep] = useState<GameStep>('intro');
  const [overlay, setOverlay] = useState<OverlayType>(null);
  const [answerId, setAnswerId] = useState<UpdatableNumber>({ value: -1, time: 0 });
  const [removeAnswerId, setRemoveAnswerId] = useState<UpdatableNumber>({ value: -1, time: 0 });

  const [questionStep, setQuestionStep] = useState<number>(0);
  const [cluesDetailsOverlay, setCluesDetailsOverlay] = useState<boolean>(false);

  useEffect(() => {
    if (step === 'game-over' || reset === true) {
      setTimer(currentRoom ? SCENES_DATA[currentRoom].time : 0);
      setStep('intro');
      setOverlay(null);
      setAnswerId({ value: -1, time: 0 });
      setRemoveAnswerId({ value: -1, time: 0 });
      setQuestionStep(0);
      setCluesDetailsOverlay(false);
      dispatch({ type: 'RESET_GAME', payload: '' });
      /*
      const id = setTimeout(() => {
        onGameReset();
        clearTimeout(id);
      }, 500);
      */
    }
  }, [step, reset]);

  useEffect(() => {
    if (answerId.value !== -1) {
      Http.post('/update/answer', {
        event,
        user,
        room: +SCENES.indexOf(currentRoom),
        answer: +answerId.value,
        step: +questionStep,
      }).then(() => {
        setAnswerId({ value: -1, time: new Date().getTime() });
      });
    }
  }, [answerId, currentRoom, questionStep]);

  useEffect(() => {
    if (removeAnswerId.value !== -1) {
      Http.post('/update/remove-answer', {
        event,
        user,
        room: +SCENES.indexOf(currentRoom),
        answer: +removeAnswerId.value,
        step: +questionStep,
      }).then(() => {
        setRemoveAnswerId({ value: -1, time: new Date().getTime() });
      });
    }
  }, [removeAnswerId, currentRoom, questionStep]);

  useEffect(() => {
    if (!isAdmin) {
      IO.on('next-room', (data: string) => {
        const room = +data;
        if (room < SCENES.length) {
          dispatch({ type: 'RESET_ROOM_CLUES', payload: SCENES[room - 1] });
          dispatch({ type: 'SET_ROOM', payload: SCENES[room] });
          setStep('intro');
          IO.emit('step-in', `ROOM_${room}`);
          console.log(`step ROOM_${room}`);
        }
        /* else {
          setStep('game-over');
        }
        */
      });
      IO.on('next-questionnary', (data: string) => {
        const room = +data;
        if (room <= SCENES.length) {
          setStep('quizz');
          IO.emit('step-in', `QUESTIONNARY_${room}`);
          console.log(`step QUESTIONNARY_${room}`);
        }
      });
      IO.on('end', (data: any) => {
        setStep('game-over');
      });
    }
  }, [IO, isAdmin, currentRoom, setStep, dispatch]);

  const showRoom = useCallback(() => {
    setStep('room');
  }, [setStep]);

  const onQuizzFinished = useCallback(() => {
    setStep('waiting');
    /*
    if (SCENES.indexOf(currentRoom) < SCENES.length - 1) {
      dispatch({ type: 'RESET_ROOM_CLUES', payload: currentRoom });
      dispatch({ type: 'SET_ROOM', payload: SCENES[SCENES.indexOf(currentRoom) + 1] });
      setStep('intro');
    } else {
      setStep('game-over');
    }
    */
  }, [currentRoom, setStep, dispatch]);

  const toggleOverlay = useCallback(
    (id: OverlayType) => {
      setOverlay(overlay === id ? null : id);
    },
    [setOverlay, overlay]
  );

  /**
   * Timer
   */
  useEffect(() => {
    if (step !== 'room') {
      return undefined;
    }
    if (timer <= 0) {
      setStep('clues-details');
      setTimer(0);
      return undefined;
    }
    const timeout = setTimeout(() => {
      setTimer(timer - 1000);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [timer, setTimer, step]);

  const onOpenCluesDetails = useCallback(() => {
    setCluesDetailsOverlay(true);
  }, []);
  /**
   * Reset timer when changing rooms
   */
  useEffect(() => {
    setTimer(SCENES_DATA[currentRoom].time);
  }, [currentRoom, setTimer]);

  useEffect(() => {
    // If all the clues of the current room were found
    if (foundClues.length === SCENES_DATA[currentRoom].clues.length) {
      const t = setTimeout(() => {
        // setStep('quizz');
        setStep('clues-details');
      }, 1000);
      return () => clearTimeout(t);
    }
    return undefined;
  }, [currentRoom, foundClues, dispatch, setStep]);

  return currentRoom ? (
    <>
      {cluesDetailsOverlay && (
        <div className={scss.cluesDetailsOverlay}>
          <Overlay
            closeOverlay={() => {
              setCluesDetailsOverlay(false);
            }}
          >
            <div className="image">
              {currentRoom === 'laboratory' && <img alt="" src={diffVirt1} />}
              {currentRoom === 'hospital' && <img alt="" src={diffVirt2} />}
              {currentRoom === 'apartment' && <img alt="" src={diffVirt3} />}
            </div>
          </Overlay>
        </div>
      )}
      {step === 'intro' && <RoomIntro id={currentRoom} goToNextStep={showRoom} />}
      {(step === 'room' || step === 'clues-details') && <Room id={currentRoom} />}
      {overlay && (
        <Overlay closeOverlay={() => setOverlay(null)}>
          <Inventory />
        </Overlay>
      )}
      {overlay && (
        <Overlay closeOverlay={() => setOverlay(null)}>
          {overlay === 'inventory' && <Inventory />}
          {overlay === 'patient-folder' && <PatientFolder />}
          {overlay === 'references' && <References />}
        </Overlay>
      )}
      {(step === 'room' || step === 'clues-details') && (
        <Toolbar
          eye={step === 'clues-details'}
          event={event}
          user={user}
          toggleOverlay={toggleOverlay}
          timer={timer}
          room={currentRoom}
          onOpenCluesDetails={onOpenCluesDetails}
        />
      )}
      {step === 'quizz' && (
        <Quizz
          onRemoveAnswerId={(e: number) => {
            setRemoveAnswerId({ value: e, time: new Date().getTime() });
          }}
          onAnswerId={(e: number) => {
            setAnswerId({ value: e, time: new Date().getTime() });
          }}
          onQuestionStep={(e: number) => {
            setQuestionStep(e);
          }}
          event={event}
          user={user}
          room={currentRoom}
          onQuizzFinished={onQuizzFinished}
        />
      )}
      {step === 'waiting' && (
        <div className={scss.game}>
          <h1>Bitte warten, es geht gleich weiter</h1>
        </div>
      )}
      {/* step === 'game-over' && <GameOver /> */}
    </>
  ) : null;
};

export default Game;
