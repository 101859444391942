import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'components/room-intro/RoomIntro.scss';

interface RoomIntroProps {
  id: string;
  goToNextStep: () => void;
}

export const RoomIntro: React.FC<RoomIntroProps> = ({ id, goToNextStep }) => {
  const [t] = useTranslation('rooms');
  const [timer, setTimer] = useState(3);
  const [counting, setCounting] = useState(true);

  const startCountdown = useCallback(() => {
    setCounting(true);
  }, [setCounting]);

  useEffect(() => {
    if (!counting) {
      return undefined;
    }
    let timeout: ReturnType<typeof setTimeout>;
    if (timer > 0) {
      timeout = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      goToNextStep();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [timer, setTimer, counting, goToNextStep]);

  return (
    <div className="room-intro" onClick={startCountdown} role="button" tabIndex={0}>
      <p>
        <span style={{ position: 'relative' }}>
          {t(`title-${id}`)}
          {counting && <span className="timer">{timer}</span>}
        </span>
        <br />
        <span style={{ visibility: counting ? 'hidden' : 'visible' }}>{t(`intro-${id}`)}</span>
      </p>
    </div>
  );
};

export default RoomIntro;
