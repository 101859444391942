import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import closeSvg from 'assets/svg/close.svg';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import 'components/clue-overlay/ClueOverlay.scss';
import { CLUES } from 'Constants';

interface ClueOverlayProps {
  id: string;
  step: number;
  closeOverlay: () => void;
}

export const AdminClueOverlay: React.FC<ClueOverlayProps> = ({ id, step, closeOverlay }) => {
  const [t, i18n] = useTranslation('clues');
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(false);
  const [currentRoom, setCurrentRoom] = useState('laboratory');
  const close = useCallback(() => {
    closeOverlay();
  }, [closeOverlay]);

  useEffect(() => {
    if (!id || !currentRoom) {
      return;
    }
    const imgLeft = new Image();
    imgLeft.onerror = () => setShowLeft(false);
    imgLeft.onload = () => setShowLeft(true);
    imgLeft.src = CLUES[`${id}-left`] ? CLUES[`${id}-left`][step] : '';
    const imgRight = new Image();
    imgRight.onerror = () => setShowRight(false);
    imgRight.onload = () => setShowRight(true);
    imgRight.src = CLUES[`${id}-right`] ? CLUES[`${id}-right`][step] : '';
  }, [setShowLeft, setShowRight, currentRoom, id]);

  return (
    <>
      <div className="clue-overlay left">
        {!!id && showLeft && (
          <div className="overlay-inner">
            <TransformWrapper>
              <TransformComponent>
                <div
                  className="image"
                  style={{ backgroundImage: `url(${CLUES[`${id}-left`][step]})` }}
                />
              </TransformComponent>
            </TransformWrapper>
            {i18n.exists(`clues:Clue-${currentRoom}-${id}-left`) && (
              <caption className="clue-caption">{t(`Clue-${currentRoom}-${id}-left`)}</caption>
            )}
          </div>
        )}
      </div>
      <div className="clue-overlay right">
        {!!id && showRight && (
          <div className="overlay-inner">
            <TransformWrapper>
              <TransformComponent>
                <div
                  className="image"
                  style={{ backgroundImage: `url(${CLUES[`${id}-right`][step]})` }}
                />
              </TransformComponent>
            </TransformWrapper>
            {i18n.exists(`clues:Clue-${currentRoom}-${id}-right`) && (
              <caption className="clue-caption">{t(`Clue-${currentRoom}-${id}-right`)}</caption>
            )}
          </div>
        )}
      </div>
      <span className="close-bt" onClick={close} role="button" tabIndex={0}>
        <img src={closeSvg} alt="" />
      </span>
    </>
  );
};

export default AdminClueOverlay;
