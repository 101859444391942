import React, { useCallback } from 'react';
import closeSvg from 'assets/svg/close.svg';
import 'components/overlay/Overlay.scss';

interface OverlayProps {
  closeOverlay: () => void;
}

export const Overlay: React.FC<OverlayProps> = ({ closeOverlay, children }) => {
  const close = useCallback(() => {
    closeOverlay();
  }, [closeOverlay]);

  return (
    <>
      <div className="overlay">
        <div className="overlay-inner">{children}</div>
      </div>
      <span className="close-bt" onClick={close} role="button" tabIndex={0}>
        <img src={closeSvg} alt="" />
      </span>
    </>
  );
};

export default Overlay;
