import React from 'react';
import 'components/patient-folder/PatientFolder.scss';

interface PatientFolderProps {}

export const PatientFolder: React.FC<PatientFolderProps> = () => {
  return (
    <div className="patient-folder">
      <h3>
        <strong>Patient:</strong> Patient 128-43/S
      </h3>
      <h3>
        <strong>Geburtstag:</strong> 04.03.1960 (61 Jahre alt)
      </h3>
      <h3>
        <strong>Vorgeschichte:</strong>
      </h3>
      <p>
        08/2013: Hemicolektomie links bei stenosierendem{' '}
        <strong>Adenokarzinom im Bereich der linken Colonflexur</strong> pT3a, NO (0/14), L 1, V1 ,
        RO, MO, Stadium IIB.{' '}
        <strong>
          <i>RAS</i> mutiert
        </strong>
        .
      </p>
      <p>
        9/2014: Bisegmentresektion der Lebersegmente VNI mit kompletter Resektion von zwei kleinen
        Lebermetastasen.
      </p>
      <p>
        11/2014 bis 4/2015: Adjuvante Chemotherapie nach Folfox Schema mit insgesamt guter
        Verträglichkeit. Verzicht auf 5FU- Bolus wegen Stomatitis.
      </p>
      <p>
        11/2015 <strong>Koloskopie mit atypischem Intervallkarzinom</strong> ohne Adenom-Anteil im
        Colon transversum
      </p>
      <p>
        7/16 PET-CT mit Diagnose einer multifokalen Peritonealcarcinose, sowie einer
        Bauchdeckenmetastase im linken Oberbauch.
      </p>
    </div>
  );
};

export default PatientFolder;
