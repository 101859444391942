import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE;
axios.defaults.withCredentials = true;
class Http {
  async get(resource: any) {
    return axios.get(resource).then((res: any) => res.data);
  }

  async post(resource: any, data: any) {
    return axios.post(resource, data).then((res: any) => res.data);
  }
}

export default new Http();
