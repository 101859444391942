import apartment from 'data/apartment.json';
import hospital from 'data/hospital.json';
import laboratory from 'data/laboratory.json';
import { ScenesData } from 'room';

import bloodTestMachine from 'assets/img/clues/laboratory/blood-test-machine.jpg';
import postIt from 'assets/img/clues/laboratory/post-it.jpg';
import bloodSamplesLeft from 'assets/img/clues/laboratory/blood-samples-left.jpg';
import bloodSamplesRight from 'assets/img/clues/laboratory/blood-samples-right.jpg';
import medicalInformation from 'assets/img/clues/laboratory/medical-information.jpg';
import therapySequence from 'assets/img/clues/laboratory/therapy-sequence.jpg';
import passwordLeft from 'assets/img/clues/laboratory/password-left.jpg';
import passwordRight from 'assets/img/clues/laboratory/password-right.jpg';
import reportsLeft from 'assets/img/clues/laboratory/reports-left.jpg';
import reportsRight from 'assets/img/clues/laboratory/reports-right.jpg';
import patientFileLeft from 'assets/img/clues/laboratory/patient-file-left.jpg';
import patientFileRight from 'assets/img/clues/laboratory/patient-file-right.jpg';

import ipadLeft from 'assets/img/clues/hospital/ipad-left.jpg';
import ipadRight from 'assets/img/clues/hospital/ipad-right.jpg';
import ivLeft from 'assets/img/clues/hospital/iv-left.jpg';
import ivRight from 'assets/img/clues/hospital/iv-right.jpg';
import phoneLeft from 'assets/img/clues/hospital/phone-left.jpg';
import phoneRight from 'assets/img/clues/hospital/phone-right.jpg';
import tvRight from 'assets/img/clues/hospital/tv-right.jpg';

import bagRight from 'assets/img/clues/apartment/bag-right.jpg';
import bookRight from 'assets/img/clues/apartment/book-right.jpg';
import brochureRight from 'assets/img/clues/apartment/brochure-right.jpg';
import calendarLeft from 'assets/img/clues/apartment/calendar-left.jpg';
import calendarRight from 'assets/img/clues/apartment/calendar-right.jpg';
import kitLeft from 'assets/img/clues/apartment/kit-left.jpg';
import kitRight from 'assets/img/clues/apartment/kit-right.jpg';

export const SCENES_DATA: ScenesData = {
  laboratory,
  apartment,
  hospital,
};

export const STEPS = [
  'CONNECT',
  'START_LOGIN',
  'START',
  'OPEN_FOLDER',
  'START_GAME',
  'ROOM_0',
  'QUESTIONNARY_1',
  'ROOM_1',
  'QUESTIONNARY_2',
  'ROOM_2',
  'QUESTIONNARY_3',
];

export const ADMIN: { [key: string]: any } = Object.freeze({
  waiting_questionnary_time: 60, // 1 min
  waiting_room_time: 15, // 15,
  robot_time: 70, // 1 min 10,
});

export const SCENES: string[] = ['laboratory', 'hospital', 'apartment'];
export const CLUES: { [key: string]: string[] } = {
  'blood-samples': [bloodSamplesLeft],
  'blood-samples-left': [bloodSamplesLeft],
  'blood-samples-right': [bloodSamplesRight],
  'blood-test-machine': [bloodTestMachine],
  'blood-test-machine-right': [bloodTestMachine],
  'medical-information': [medicalInformation],
  'medical-information-right': [medicalInformation],
  password: [passwordLeft, patientFileLeft],
  'password-left': [passwordLeft, patientFileLeft],
  'password-right': [passwordRight, patientFileRight],
  reports: [reportsLeft],
  'reports-left': [reportsLeft],
  'reports-right': [reportsRight],
  'therapy-sequence': [therapySequence],
  'therapy-sequence-right': [therapySequence],
  'post-it': [postIt],
  'post-it-right': [postIt],

  ipad: [ipadLeft],
  'ipad-left': [ipadLeft],
  'ipad-right': [ipadRight],
  iv: [ivLeft],
  'iv-left': [ivLeft],
  'iv-right': [ivRight],
  phone: [phoneLeft],
  'phone-left': [phoneLeft],
  'phone-right': [phoneRight],
  tv: [tvRight],
  'tv-right': [tvRight],

  bag: [bagRight],
  'bag-right': [bagRight],
  book: [bookRight],
  'book-right': [bookRight],
  brochure: [brochureRight],
  'brochure-right': [brochureRight],
  calendar: [calendarLeft],
  'calendar-left': [calendarLeft],
  'calendar-right': [calendarRight],
  kit: [kitLeft],
  'kit-left': [kitLeft],
  'kit-right': [kitRight],
};

export default { SCENES, SCENES_DATA, CLUES };
