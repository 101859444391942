import React, { useState, useEffect } from 'react';
import animation1 from 'assets/img/animation-1.jpg';
import animation2 from 'assets/img/animation-2.jpg';
import background from 'assets/img/background.jpg';
import scss from './animation.module.scss';

interface AnimProps {
  reset: boolean;
  onAnimationDone: () => void;
  onGameReset: () => void;
}

export const Animation: React.FC<AnimProps> = ({ reset, onAnimationDone, onGameReset }) => {
  const [step, setStep] = useState<number>(0);
  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;
    if (step < 5) {
      const time = step === 0 || step === 2 || step === 4 ? 4000 : 1500;
      // const time = 4000;
      timerId = setTimeout(() => {
        setStep(step + 1);
        clearTimeout(timerId);
      }, time);
    } else {
      onAnimationDone();
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [step]);

  useEffect(() => {
    if (reset === true) {
      setStep(0);
      onGameReset();
    }
  }, [reset]);

  return (
    <div className={scss.animation}>
      {(step === 0 || step === 1) && (
        <div
          className={`${step === 0 ? 'fadein' : 'fadeout'}`}
          style={{ backgroundImage: `url(${animation2})` }}
        />
      )}
      {(step === 2 || step === 3) && (
        <div
          className={`${step === 2 ? 'fadein' : 'fadeout'}`}
          style={{ backgroundImage: `url(${animation1})` }}
        />
      )}
      {(step === 4 || step === 5) && (
        <div
          className={`${step === 4 ? 'fadein' : ''}`}
          style={{ backgroundImage: `url(${background})` }}
        />
      )}
    </div>
  );
};
export default Animation;
