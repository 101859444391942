import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import usePortal from 'react-useportal';
import Marzipano, { Viewer } from 'marzipano';
import DeviceOrientationControlMethod from 'lib/DeviceOrientationControlMethod';

import { ClueData, SceneData } from 'room';
import ClueOverlay from 'components/clue-overlay/ClueOverlay';
import { SCENES_DATA } from 'Constants';
import { VectibixContext } from 'context/vectibix-context';
import ClueValidator from 'validations/clue-validator';

import 'components/Room/Room.scss';

interface SceneProgression {
  id: string;
  sceneLeft: any;
  sceneRight: any;
  complete: boolean;
  foundClues: string[];
}

interface RoomProps {
  id: string;
}

interface CluePair {
  left: HTMLDivElement;
  right: HTMLDivElement;
}

export const Room: React.FC<RoomProps> = ({ id }) => {
  const {
    state: { clues, showingClue },
    dispatch,
  } = useContext(VectibixContext);
  const foundClues: string[] = clues[id] || [];
  const [started, setStarted] = useState(false);
  const [currentClueTarget, setCurrentClueTarget] = useState<any>({});
  const [step, setStep] = useState(0);
  const [viewer, setViewer] = useState<any>(null);
  const [useGyro, setUseGyro] = useState(false);
  const { Portal } = usePortal({
    bindTo: document.getElementById('root') || undefined,
  });

  const roomElement = useRef<HTMLDivElement>(null);
  const roomAfterElement = useRef<HTMLDivElement>(null);
  const scenes: React.MutableRefObject<SceneProgression[]> = useRef<SceneProgression[]>([]);
  const clueElements: React.MutableRefObject<CluePair[]> = useRef<CluePair[]>([]);

  const startRoom = useCallback(() => {
    function requestPermissionForIOS() {
      window.DeviceOrientationEvent.requestPermission()
        .then((response) => {
          if (response === 'granted') {
            setUseGyro(true);
          }
          setStarted(true);
        })
        .catch((e) => {
          setStarted(true);
          console.error(e);
        });
    }

    if (window.DeviceOrientationEvent) {
      if (typeof window.DeviceOrientationEvent.requestPermission === 'function') {
        requestPermissionForIOS();
      } else {
        setUseGyro(true);
        setStarted(true);
      }
    } else {
      setStarted(true);
    }
  }, [setStarted, setUseGyro]);

  const findClue = useCallback(
    (clue: string): void => {
      if (!foundClues.includes(clue)) {
        dispatch({ type: 'FIND_CLUE', payload: clue });
        scenes.current.find((s) => s.id === id)?.foundClues.push(clue);
      }
    },
    [foundClues, dispatch, scenes, id]
  );

  const closeOverlay: any = useCallback(
    (e: any) => {
      const { scene, hint } = currentClueTarget.dataset;
      if (!showingClue) {
        return;
      }
      const { clues: sceneClues } = SCENES_DATA[scene];
      const clue = sceneClues.find((value) => {
        return hint === value.id;
      });
      if (clue?.validators?.length && clue.validators.length === step) {
        findClue(showingClue);
      } else if (!clue?.validators) {
        findClue(showingClue);
      }
      dispatch({ type: 'SET_SHOW_CLUE', payload: undefined });
      setStep(0);
    },
    [step, dispatch, showingClue, findClue, currentClueTarget]
  );

  const onValidate = useCallback(
    (e) => {
      if (e) {
        setStep(step + 1);
      }
    },
    [step]
  );

  const onClueClicked = useCallback(
    (e) => {
      setCurrentClueTarget(e.currentTarget);
      const { scene, hint } = e.currentTarget.dataset;
      const { clues: sceneClues } = SCENES_DATA[scene];
      const clue = sceneClues.find((value) => {
        return hint === value.id;
      });
      if (clue?.dependencies) {
        const dependencies = clue.dependencies.find((dependency) => {
          return !foundClues.find((value) => {
            return dependency === value;
          });
        });
        if (dependencies === undefined) {
          dispatch({ type: 'SET_SHOW_CLUE', payload: hint });
        }
      } else {
        dispatch({ type: 'SET_SHOW_CLUE', payload: hint });
      }
    },
    [dispatch, foundClues]
  );

  // const goToScene = useCallback(
  //   (id) => {
  //     if (id !== id) {
  //       setFoundClues(scenes.current.find((s) => s.id === id).foundClues);
  //       setCurrentScene(id);
  //     }
  //   },
  //   [id, setFoundClues, setCurrentScene, scenes]
  // );

  useEffect(() => {
    if (!roomElement.current) {
      return;
    }
    if (viewer === null) {
      const leftView = new Viewer(roomElement.current, {
        controls: {
          mouseViewMode: 'drag',
        },
      });
      const rightView = new Viewer(roomAfterElement.current, {
        controls: {
          mouseViewMode: 'drag',
        },
      });
      const deviceOrientationControlMethod = new DeviceOrientationControlMethod();
      const controls = leftView.controls();
      controls.registerMethod('deviceOrientation', deviceOrientationControlMethod);

      const geometry = new Marzipano.CubeGeometry(SCENES_DATA[id].levels);
      const limiter = Marzipano.RectilinearView.limit.traditional(
        1024,
        (100 * Math.PI) / 180,
        (120 * Math.PI) / 180
      );
      const view = new Marzipano.RectilinearView(null, limiter);

      Object.keys(SCENES_DATA).forEach((key: string): void => {
        const data: SceneData = SCENES_DATA[key];
        const sourceLeft = Marzipano.ImageUrlSource.fromString(
          `/tiles/${data.id}-left/{z}/{f}/{y}/{x}.jpg`,
          {
            cubeMapPreviewUrl: `/tiles/${data.id}-left/preview.jpg`,
          }
        );
        const sLeft = leftView.createScene({
          source: sourceLeft,
          geometry,
          view,
          pinFirstLevel: true,
        });
        const sourceRight = Marzipano.ImageUrlSource.fromString(
          `/tiles/${data.id}-right/{z}/{f}/{y}/{x}.jpg`,
          {
            cubeMapPreviewUrl: `/tiles/${data.id}-right/preview.jpg`,
          }
        );
        const sRight = rightView.createScene({
          source: sourceRight,
          geometry,
          view,
          pinFirstLevel: true,
        });
        // Create Clues
        data.clues.forEach((clue: ClueData) => {
          const element: HTMLDivElement = document.createElement('div');
          element.dataset.hint = clue.id;

          const wrapper: HTMLDivElement = document.createElement('div');
          wrapper.style.position = 'relative';
          wrapper.style.width = '100%';
          wrapper.style.height = '100%';
          element.appendChild(wrapper);

          const touch: HTMLDivElement = document.createElement('div');
          touch.style.width = `${clue.width || 100}px`;
          touch.style.height = `${clue.height || 100}px`;
          touch.style.transform = 'translate(-50%, -50%)';
          touch.style.position = 'absolute';
          wrapper.appendChild(touch);

          // Helper on env gate
          // if (clue.id === 'kitchen' || clue.id === 'bedroom') {
          //   const img: HTMLImageElement = new Image();
          //   img.style.width = '50px';
          //   img.style.transform = 'translate(-50%, -50%)';
          //   img.src = require('assets/svg/next-room_button.svg');
          //   img.style.position = 'absolute';
          //   img.style.pointerEvents = 'none';
          //   wrapper.appendChild(img);
          // }

          if (clue.toScene) {
            element.dataset.toScene = clue.toScene;
            touch.style.width = '125px';
            touch.style.height = '250px';
            element.style.transform = 'translate(-50%, -50%)';
          }
          // if (clue.preventAutoFind) {
          //   element.dataset.preventAutoFind = true;
          // }

          element.dataset.scene = data.id;
          const cluePair: CluePair = {
            left: element,
            right: element.cloneNode(true) as HTMLDivElement,
          };
          clueElements.current.push(cluePair);
          sLeft.hotspotContainer().createHotspot(cluePair.left, {
            yaw: clue.yaw,
            pitch: clue.pitch,
          });
          sRight.hotspotContainer().createHotspot(cluePair.right, {
            yaw: clue.yaw,
            pitch: clue.pitch,
          });
        });

        scenes.current.push({
          id: data.id,
          sceneLeft: sLeft,
          sceneRight: sRight,
          complete: false,
          foundClues: [],
        });
      });

      // Gyroscope
      if (useGyro) {
        deviceOrientationControlMethod.getPitch((err: any, pitch: number) => {
          if (!err) {
            view.setPitch(pitch);
          }
        });
        controls.enableMethod('deviceOrientation');
      }

      roomElement.current?.parentNode?.parentNode?.prepend(roomElement.current.parentNode);
      view.setParameters(SCENES_DATA[id].initialViewParameters);
      setViewer(leftView);
      startRoom();
    }
  }, [
    id,
    started,
    viewer,
    setViewer,
    roomElement,
    roomAfterElement,
    foundClues,
    useGyro,
    startRoom,
  ]);

  /**
   * Init scenes
   */
  useEffect(() => {
    if (id) {
      scenes.current.find((s) => s.id === id)?.sceneLeft.switchTo();
      scenes.current.find((s) => s.id === id)?.sceneRight.switchTo();
      // Set touch listeners on the scenes' clues
      clueElements.current.forEach(({ left, right }: CluePair) => {
        if (left.dataset.toScene) {
          // el.onclick = () => goToScene(el.dataset.room);
        } else {
          left.onclick = onClueClicked;
          right.onclick = onClueClicked;
        }
      });
    }
  }, [id, scenes, onClueClicked, clueElements]);

  useEffect(() => {
    dispatch({ type: 'SET_SHOW_CLUE', payload: undefined });
  }, [id, dispatch]);

  /**
   * The App container is not touchable when moving in the 360 envirtonment
   */
  useEffect(() => {
    const app: HTMLElement | null = document.querySelector('.App');
    if (!app) {
      return undefined;
    }
    if (started && showingClue === null) {
      // app.style.pointerEvents = 'none';
    } else {
      app.style.pointerEvents = 'initial';
    }
    return () => {
      app.style.pointerEvents = 'initial';
    };
  }, [showingClue, started]);

  return (
    <>
      <Portal>
        <div ref={roomElement} className="scene left" />
        <div ref={roomAfterElement} className="scene right" />
      </Portal>
      {showingClue && <ClueValidator id={showingClue} onValidate={onValidate} />}
      {showingClue && <ClueOverlay id={showingClue} step={step} closeOverlay={closeOverlay} />}
    </>
  );
};

export default Room;
