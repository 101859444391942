import React, { useCallback, useContext, useEffect, useState, useLayoutEffect } from 'react';
import ControlPanel from 'components/administrator/ControlPanel';
import Navigator from 'components/administrator/Navigator';
import Http from 'components/administrator/http';
import { ADMIN, STEPS } from 'Constants';
import Overlay from 'components/overlay/Overlay';
import diffVirt1 from 'assets/img/admin/diff-virt1.png';
import diffVirt2 from 'assets/img/admin/diff-virt2.png';
import diffVirt3 from 'assets/img/admin/diff-virt3.png';
import logo from 'assets/img/logo.png';
import scss from './administrator.module.scss';
import RoomMonitoring from './RoomMonitoring';
import QuestionnaryMonitoring from './QuestionnaryMonitoring';

interface AdministratorProps {
  IO: any;
  event: string | null;
}

interface UserData {
  name: string;
  rooms: any[];
}
interface AnswerData {
  name: string;
  questionnaries: any[];
}

const Administrator: React.FC<AdministratorProps> = ({ IO, event }) => {
  const defaultAnswers = [
    {
      name: 'default',
      questionnaries: [
        { answered: [[], [], [], []] },
        { answered: [[], [], []] },
        { answered: [[], []] },
      ],
    },
  ];

  const [started, setStarted] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [monitoringStep, setMonitoringStep] = useState<string>('room');
  const [navigationEnabled, setNavigationEnabled] = useState<boolean>(false);
  const [users, setUsers] = useState<UserData[]>([]);
  const [answers, setAnswers] = useState<AnswerData[]>(defaultAnswers);

  const [timer, setTimer] = useState<number>(ADMIN.robot_time);
  const [robotSpeechStarted, setRobotSpeechStarted] = useState<boolean>(false);
  const [gameStarted, setGameStarted] = useState<boolean>(false);
  const [roomId, setRoomId] = useState<number>(0);
  const [eyeLayerOn, setEyeLayerOn] = useState<boolean>(false);
  const [underReset, setUnderReset] = useState<boolean>(false);
  const [flowStep, setFlowStep] = useState<string>('');

  /*
    useEffect(() => {
      const timerFinished = sessionStorage.getItem('timer-finished') === 'true';
      if (timerFinished) {
        setNavigationEnabled(true);
      }
    }, []);
  */

  const onReset = () => {
    setStarted(false);
    setPage(0);
    setMonitoringStep('room');
    setNavigationEnabled(false);
    setUsers([]);
    setAnswers(defaultAnswers);
    setTimer(ADMIN.robot_time);
    setRobotSpeechStarted(false);
    setGameStarted(false);
    setRoomId(0);
    setEyeLayerOn(false);
    setFlowStep('');
  };

  const onEyeLayer = (e: boolean) => {
    setEyeLayerOn(e);
    // console.log(e);
  };

  const onMonitoringStep = useCallback((value: string) => {
    setMonitoringStep(value);
  }, []);

  const onNavigate = useCallback((e) => {
    setPage(e);
    // console.log(e);
  }, []);

  const onStarted = useCallback(() => {
    Http.post('/start/login', { event }).then((res) => {
      setStarted(true);
    });
  }, []);

  const onSessionReset = useCallback(() => {
    let timerId: ReturnType<typeof setTimeout>;
    if (!underReset) {
      Http.post('/logout', {
        event,
      }).then((res) => {
        // setUnderReset(false);
        setUnderReset(true);
        timerId = setTimeout(() => {
          onReset();
          setUnderReset(false);
          clearTimeout(timerId);
        }, 500);
      });
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [underReset]);

  useEffect(() => {
    /*
    Http.get(`/answers/${event}`).then((res) => {
      if (res.success) {
        setAnswers(res.answers);
      }
    });
    */
    Http.get(`/users/${event}`).then((res) => {
      if (res.success) {
        setUsers(res.users);
      }
    });
    Http.get(`/flow-step/${event}`).then((data) => {
      const { step } = data;
      setFlowStep(step);
      if (STEPS.indexOf(step) >= STEPS.indexOf('START_LOGIN')) {
        onStarted();
      }
      if (STEPS.indexOf(step) >= STEPS.indexOf('START')) {
        setRobotSpeechStarted(true);
      }
      //
    });
  }, []);

  useEffect(() => {
    if (flowStep) {
      if (flowStep.indexOf('ROOM') !== -1) {
        const number = flowStep.substr(5, 1);
        setGameStarted(true);
        setMonitoringStep('room');
        setPage(+number + 1);
        setNavigationEnabled(true);
        setTimer(ADMIN.waiting_room_time);
        // setRoomId(+number);
      } else if (flowStep.indexOf('QUESTIONNARY') !== -1) {
        const number = +flowStep.substr(13, 1) - 1;
        setGameStarted(true);
        setMonitoringStep('questionnary');
        setRoomId(number);
        setNavigationEnabled(true);
        setPage(number + 1);
        setTimer(ADMIN.waiting_questionnary_time);
      }
    }
  }, [flowStep]);

  useEffect(() => {
    if (IO) {
      IO.on('user-entered', (data: string) => {
        const info: string[] = JSON.parse(data);
        const userDataList: UserData[] = info.map((va, ka) => {
          const currentUserData: UserData[] = users.filter((vb, kb) => {
            return vb.name === va;
          });
          if (currentUserData.length === 0) {
            const ret: UserData = {
              name: `${va}`,
              rooms: [{ resolved: [] }, { resolved: [] }, { resolved: [] }],
            };
            return ret;
          }
          return currentUserData[0];
        });
        setUsers(userDataList);
        /* else {
          setUsers([
            {
              name: `${userInfo.name}`,
              rooms: [{ resolved: [] }, { resolved: [] }, { resolved: [] }],
            },
          ]);
        } */
      });
    }
  }, [IO, users]);

  useEffect(() => {
    if (IO) {
      IO.on('update', (data: string) => {
        if (data === 'resolved') {
          Http.get(`/users/${event}`).then((res) => {
            if (res.success) {
              setUsers(res.users);
            }
          });
        } else if (data === 'answered') {
          Http.get(`/answers/${event}`).then((res) => {
            if (res.success) {
              setAnswers(res.answers);
            }
          });
        }
      });
    }
  }, [IO]);

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;
    if (robotSpeechStarted) {
      if (timer > 0) {
        timerId = setTimeout(() => {
          const time = timer - 1;
          setTimer(time >= 0 ? time : 0);
          if (time <= 0) {
            setNavigationEnabled(true);
          }
          clearTimeout(timerId);
        }, 1000);
      }
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [timer, robotSpeechStarted]);

  return (
    <>
      <div className={scss.reset}>
        <button
          className={`reset-button ${underReset ? 'under-reset' : ''}`}
          type="button"
          onClick={onSessionReset}
        >
          <b>Zurücksetzen</b>
        </button>
      </div>
      {!started && (
        <div className={scss.start}>
          <img src={logo} alt="" />
          <h1>
            <span>V</span>TOPIA MEDICAL TRAINING CENTER
          </h1>
          <h2>EIN FALL FÜR VECTIBIX</h2>
          <button style={{ cursor: 'pointer' }} type="button" onClick={onStarted}>
            <b>START</b>
          </button>
        </div>
      )}
      {eyeLayerOn && (
        <div className={scss.eyeLayer}>
          <Overlay
            closeOverlay={() => {
              setEyeLayerOn(false);
            }}
          >
            <div className="image">
              {roomId === 0 && <img alt="" src={diffVirt1} />}
              {roomId === 1 && <img alt="" src={diffVirt2} />}
              {roomId === 2 && <img alt="" src={diffVirt3} />}
            </div>
          </Overlay>
        </div>
      )}
      {started && (
        <div className={scss.administrator}>
          <Navigator
            flowStep={flowStep}
            onReset={onReset}
            monitoringStep={monitoringStep}
            event={event}
            onSetTimer={(value: number) => {
              setTimer(value);
            }}
            enabled={navigationEnabled}
            timer={timer}
            IO={IO}
            onNavigate={onNavigate}
            onMonitoringStep={onMonitoringStep}
            onEyeLayer={onEyeLayer}
            onSessionReset={onSessionReset}
            reset={underReset}
          />
          {!gameStarted && (
            <ControlPanel
              IO={IO}
              event={event}
              reset={underReset}
              flowStep={flowStep}
              onGameStarted={() => {
                setGameStarted(true);
              }}
              timer={timer}
              onRobotSpeechStarted={() => {
                setRobotSpeechStarted(true);
              }}
              onSetTimer={(value: number) => {
                setTimer(value);
              }}
            />
          )}
          {page > 0 && monitoringStep === 'room' && (
            <RoomMonitoring
              onRoomId={(e: number) => {
                setRoomId(e);
              }}
              page={page}
              users={users}
            />
          )}
          {page > 0 && monitoringStep === 'questionnary' && (
            <QuestionnaryMonitoring roomId={roomId} answers={answers} />
          )}
        </div>
      )}
    </>
  );
};

export default Administrator;
