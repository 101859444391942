import { SCENES_DATA } from 'Constants';
import { VectibixContext } from 'context/vectibix-context';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import 'components/references/References.scss';

interface ReferencesProps {}

export const References: React.FC<ReferencesProps> = () => {
  const {
    state: { showingClue, currentRoom },
  } = useContext(VectibixContext);
  const [t] = useTranslation('clues');
  const [tCommon] = useTranslation('common');
  const refs = SCENES_DATA[currentRoom].clues.find((c) => c.id === showingClue)?.references;

  return (
    <>
      <h2>{tCommon('References')}</h2>
      <ul className="references">
        {refs?.map((ref) => (
          <li>
            {ref}
            {'. '}
            {t(`Clue-${currentRoom}-${showingClue}-ref-${ref}`)}
          </li>
        ))}
      </ul>
    </>
  );
};

export default References;
