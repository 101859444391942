/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import quizz from 'locales/de/quizz.json';
import apartment from 'data/apartment.json';
import hospital from 'data/hospital.json';
import laboratory from 'data/laboratory.json';
import scss from './questionnary-monitoring.module.scss';

interface QMProps {
  answers: AnswerData[];
  roomId: number;
}
interface AnswerData {
  name: string;
  questionnaries: any[];
}
const SCENES_DATA: any = [laboratory, hospital, apartment];
const q: any = quizz;
const infoBubble: any = [
  [
    q['question-laboratory-0'],
    q['question-laboratory-1'],
    q['question-laboratory-2'],
    q['question-laboratory-3'],
  ],
  [q['question-hospital-0'], q['question-hospital-1'], q['question-hospital-2']],
  [q['question-apartment-0'], q['question-apartment-1']],
];

const infoBubbleAnswer: any = [
  [
    ['option-laboratory-0-0', 'option-laboratory-0-1'],
    ['option-laboratory-1-0', 'option-laboratory-1-1', 'option-laboratory-1-2'],
    ['option-laboratory-2-0', 'option-laboratory-2-1', 'option-laboratory-2-2'],
    ['option-laboratory-3-0', 'option-laboratory-3-1'],
  ],
  [
    [
      'option-hospital-0-0',
      'option-hospital-0-1',
      'option-hospital-0-2',
      'option-hospital-0-3',
      'option-hospital-0-4',
    ],
    ['option-hospital-1-0', 'option-hospital-1-1', 'option-hospital-1-2'],
    ['option-hospital-2-0', 'option-hospital-2-1', 'option-hospital-2-2', 'option-hospital-0-3'],
  ],
  [
    ['option-apartment-0-0', 'option-apartment-0-1', 'option-apartment-0-2'],
    ['option-apartment-1-0', 'option-apartment-1-1'],
  ],
];

const QuestionnaryMonitoring: React.FC<QMProps> = ({ answers, roomId }) => {
  const [questions, setQuestions] = useState<any[]>([]);
  const [connectUsers, setConnectUsers] = useState<number>(0);
  const [infoBubbleId, setInfoBubbleId] = useState<number>(-1);
  const [infoBubbleAnswerId, setInfoBubbleAnswerId] = useState<string>('');
  useEffect(() => {
    // todo
    /* const a = [
      {
        name: '02072021-0',
        questionnaries: [
          { answered: [[0], [1, 3], [], []] },
          { answered: [[], [], []] },
          { answered: [[], []] },
        ],
      },
      {
        name: '02072021-1',
        questionnaries: [
          { answered: [[0, 1], [], [2], []] },
          { answered: [[], [], []] },
          { answered: [[], []] },
        ],
      },
    ]; */
    const a: any = answers;
    const questionnary: any = [];
    setConnectUsers(a.length);
    a.forEach((user: any) => {
      const userQuestionnary = user.questionnaries[roomId].answered;
      userQuestionnary.forEach((va: any, index: number) => {
        if (!questionnary[index]) {
          questionnary[index] = {};
        }
        const question = questionnary[index];
        va.forEach((vb: number) => {
          if (!question[vb]) {
            question[vb] = 1;
          } else {
            question[vb] += 1;
          }
        });
      });
    });
    setQuestions(questionnary);
  }, [answers]);

  return (
    <>
      <div className={scss.center}>
        {roomId === 0 && <h3>Die Teilnehmer beantworten jetzt den ersten Fragebogen</h3>}
        {roomId === 1 && <h3>Die Teilnehmer beantworten jetzt den zweiten Fragebogen</h3>}
        {roomId === 2 && <h3>Die Teilnehmer beantworten jetzt den dritten Fragebogen</h3>}
      </div>
      <div className={scss.questionnaryMonitoring}>
        {questions.map((va: any, ka: number) => {
          return (
            <div key={ka}>
              <div style={{ fontFamily: 'Jost-Medium' }}>
                <i
                  role="button"
                  tabIndex={0}
                  onMouseEnter={() => {
                    setInfoBubbleId(ka);
                  }}
                  onMouseLeave={() => {
                    setInfoBubbleId(-1);
                  }}
                  className="infoTitle"
                >
                  {infoBubbleId === ka ? (
                    <>
                      <b>Frage {ka + 1}</b>{' '}
                    </>
                  ) : (
                    <>Frage {ka + 1} </>
                  )}
                </i>
                <span
                  role="button"
                  tabIndex={0}
                  onMouseEnter={() => {
                    setInfoBubbleId(ka);
                  }}
                  onMouseLeave={() => {
                    setInfoBubbleId(-1);
                  }}
                >
                  i
                </span>
                <div
                  dangerouslySetInnerHTML={{ __html: infoBubble[roomId][ka] }}
                  className="info"
                  style={{ display: infoBubbleId === ka ? '' : 'none' }}
                >
                  {/* infoBubble[roomId][ka] */}
                </div>
              </div>
              <div>
                {(() => {
                  const options = new Array(SCENES_DATA[roomId].quizzes[ka].options);
                  const { correct } = SCENES_DATA[roomId].quizzes[ka];
                  return options.fill(0).map((_vb: any, kb: number) => {
                    return (
                      <div key={kb} className="answer">
                        <span
                          role="button"
                          tabIndex={0}
                          style={{ cursor: 'pointer' }}
                          className={`${correct.indexOf(kb) !== -1 ? 'correct' : ''}`}
                          onMouseEnter={() => {
                            setInfoBubbleAnswerId(`${ka}-${kb}`);
                          }}
                          onMouseLeave={() => {
                            setInfoBubbleAnswerId('');
                          }}
                        >
                          {infoBubbleAnswerId !== `${ka}-${kb}` ? (
                            <>Antwort {kb + 1}</>
                          ) : (
                            <>
                              <b>
                                <>Antwort {kb + 1}</>
                              </b>
                            </>
                          )}
                        </span>
                        <span>
                          {va[kb] || 0}/{connectUsers}
                        </span>
                        <div
                          dangerouslySetInnerHTML={{ __html: q[infoBubbleAnswer[roomId][ka][kb]] }}
                          className="info"
                          style={{ display: infoBubbleAnswerId === `${ka}-${kb}` ? '' : 'none' }}
                        >
                          {/* q[infoBubbleAnswer[roomId][ka][kb]] */}
                        </div>
                      </div>
                    );
                  });
                })()}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default QuestionnaryMonitoring;
