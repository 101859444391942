import React, { useCallback, useRef, useState } from 'react';
import scss from './password.module.scss';

const Password = (props: any) => {
  const password = useRef<HTMLInputElement>(null);
  const [wrongPassword, setWrongPassword] = useState(false);
  const [goodPassword, setGoodPassword] = useState(false);
  const { onValidate } = props;
  const validPassword = useCallback(() => {
    const value = password?.current?.value;
    if (value && value.toUpperCase() === 'VTOPIA') {
      setGoodPassword(true);
      onValidate(true);
    } else {
      setWrongPassword(true);
    }
  }, [password, onValidate]);

  return (
    <>
      {!goodPassword && (
        <div className={scss.container}>
          <label htmlFor="password">Passwort</label>
          <input
            style={{ textTransform: 'uppercase' }}
            id="password"
            name="password"
            type="text"
            ref={password}
          />
          <button type="button" onClick={validPassword}>
            Ok
          </button>
          {wrongPassword && <p>Falsches Passwort</p>}
        </div>
      )}
    </>
  );
};

export default Password;
