import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import commonDe from 'locales/de/common.json';
import roomsDe from 'locales/de/rooms.json';
import cluesDe from 'locales/de/clues.json';
import quizzDe from 'locales/de/quizz.json';
import adminDe from 'locales/de/admin.json';

export const resources = {
  de: {
    rooms: roomsDe,
    common: commonDe,
    clues: cluesDe,
    quizz: quizzDe,
    admin: adminDe,
  },
};
export default resources;

i18n.use(initReactI18next).init({
  interpolation: {
    escapeValue: false, // React already does escaping
  },
  lng: 'de',
  defaultNS: 'common',
  ns: ['common', 'clues', 'rooms', 'quizz', 'admin'],
  resources,
});
