/* eslint-disable react/no-danger */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { SCENES_DATA } from 'Constants';
import { Quizz as QuizzData } from 'room';
import play from 'assets/svg/play.svg';
import Http from 'components/administrator/http';

import 'components/quizz/Quizz.scss';

interface QuizzProps {
  room: string;
  event: string | null;
  user: string | null;
  onQuizzFinished: () => void;
  onAnswerId: (e: number) => void;
  onRemoveAnswerId: (e: number) => void;
  onQuestionStep: (e: number) => void;
}

export const Quizz: React.FC<QuizzProps> = ({
  room,
  event,
  user,
  onQuizzFinished,
  onAnswerId,
  onRemoveAnswerId,
  onQuestionStep,
}) => {
  const [t] = useTranslation('rooms');
  const [tCommon] = useTranslation('common');
  const [tQuizz] = useTranslation('quizz');

  const [questionId, setQuestionId] = useState(0);
  const [questionStep, setQuestionStep] = useState(0);
  const [answerOptions, setAnswerOptions] = useState<string[]>([]);
  const [selected, setSelected] = useState<number[]>([]);

  const toggleOption = useCallback(
    (e) => {
      const {
        currentTarget: { dataset },
      } = e;
      if (!dataset) {
        return;
      }
      const { id } = dataset;
      if (id === undefined) {
        return;
      }
      if (selected.includes(parseInt(id, 10))) {
        setSelected(selected.filter((i) => i !== parseInt(id, 10)));
        onRemoveAnswerId(id);
      } else {
        onAnswerId(id);
        setSelected([...selected, parseInt(id, 10)]);
      }
    },
    [selected, setSelected]
  );

  const validateAnswer = useCallback(() => {
    setSelected([]);
    const id = questionId + 1;
    setQuestionId(id);
    const step = questionStep + 1;
    setQuestionStep(step);
    onQuestionStep(step);
  }, [selected, setSelected, setQuestionId, questionId, questionStep, onQuestionStep]);

  const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const previous: any = usePrevious({ room });

  useEffect(() => {
    if (previous === undefined || previous.room !== room) {
      setQuestionStep(0);
      onQuestionStep(0);
    }
  }, [room, previous]);
  /**
   * Init question content
   */
  useEffect(() => {
    const quizzes: QuizzData[] | undefined = SCENES_DATA[room]?.quizzes;
    if (!quizzes) {
      return;
    }
    if (questionId >= quizzes.length) {
      Http.post('/end/user-questionnary', { event }).then((data) => {
        onQuizzFinished();
      });
      return;
    }
    const options: string[] = [];
    for (let i: number = 0; i < quizzes[questionId].options; i++) {
      options[i] = tQuizz(`option-${room}-${questionId}-${i}`);
    }
    setAnswerOptions(options);
  }, [setAnswerOptions, questionId, room, tQuizz, onQuizzFinished]);

  return (
    <div className="quizz">
      <p>
        <span style={{ position: 'relative' }}>
          {t(`title-${room}`)}
          {' - '}
          {tCommon(`Quizz`)}
          <br />
          <span>{tCommon(`Select the right answers`)}</span>
        </span>
        <br />
        <br />
        <span
          className="question"
          style={{ display: 'block' }}
          dangerouslySetInnerHTML={{ __html: tQuizz(`question-${room}-${questionId}`) }}
        >
          {/* tQuizz(`question-${room}-${questionId}`) */}
        </span>
        {answerOptions.map((o, i) => (
          <span
            key={i}
            data-id={i}
            className="answer-option"
            onClick={toggleOption}
            role="button"
            tabIndex={0}
          >
            <span className={classNames('checkbox', { checked: selected.includes(i) })} />
            <span
              className="option-text"
              style={{ display: 'block' }}
              dangerouslySetInnerHTML={{ __html: o }}
            >
              {/* o */}
            </span>
          </span>
        ))}
      </p>
      <button
        type="button"
        className="bt-validate"
        disabled={selected.length === 0}
        onClick={validateAnswer}
      >
        <img src={play} alt="" />
      </button>
    </div>
  );
};

export default Quizz;
