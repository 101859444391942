import { SCENES } from 'Constants';
import React from 'react';

type ActionType = 'SET_ROOM' | 'FIND_CLUE' | 'RESET_ROOM_CLUES' | 'SET_SHOW_CLUE' | 'RESET_GAME';

export interface CluesDictionary {
  [key: string]: string[];
}
export interface VectibixState {
  currentRoom: string;
  clues: CluesDictionary;
  showingClue?: string;
  updated: number;
}

export interface VectibixContextAction {
  type: ActionType;
  payload?: string;
}

export const initialState: VectibixState = {
  currentRoom: SCENES[0],
  clues: {},
  updated: 0,
};

export const VectibixContext = React.createContext<{
  state: VectibixState;
  dispatch: React.Dispatch<VectibixContextAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

const reducer = (state: VectibixState, action: VectibixContextAction): VectibixState => {
  const { payload } = action;
  switch (action.type) {
    case 'RESET_GAME':
      return { ...initialState };

    case 'SET_ROOM':
      if (!payload) {
        return state;
      }
      return {
        ...state,
        currentRoom: payload,
      };
    case 'RESET_ROOM_CLUES':
      if (!payload) {
        return state;
      }
      return {
        ...state,
        clues: {
          ...state.clues,
          [state.currentRoom]: [],
        },
      };

    case 'FIND_CLUE':
      if (
        !state.currentRoom ||
        !payload ||
        (state.clues[state.currentRoom] && state.clues[state.currentRoom].includes(payload))
      ) {
        return state;
      }
      return {
        ...state,
        updated: new Date().getTime(),
        clues: {
          ...state.clues,
          [state.currentRoom]: [...(state.clues[state.currentRoom] || []), payload],
        },
      };

    case 'SET_SHOW_CLUE':
      return {
        ...state,
        showingClue: payload,
      };

    default:
      return state;
  }
};

export default reducer;
