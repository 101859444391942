import React, { useContext, useEffect, useRef, useCallback, useState } from 'react';
import 'components/toolbar/Toolbar.scss';
import { VectibixContext } from 'context/vectibix-context';
import { useTranslation } from 'react-i18next';
import { Textfit } from 'react-textfit';
import classNames from 'classnames';

import folder from 'assets/svg/folder.svg';
import box from 'assets/svg/box.svg';
import book from 'assets/svg/book.svg';
import clock from 'assets/svg/timer.svg';
import clueOn from 'assets/img/check.png';
import clueOff from 'assets/svg/tick-empty.svg';
import { SCENES, SCENES_DATA } from 'Constants';
import { formatTime } from 'lib/utils';
import Http from 'components/administrator/http';
import eyeSvg from 'assets/svg/eye.svg';

type OverlayType = 'patient-folder' | 'inventory' | 'references' | null;

interface ToolbarProps {
  event: string | null;
  user: string | null;
  room: string;
  timer: number;
  eye: boolean;
  toggleOverlay: (id: OverlayType) => void;
  onOpenCluesDetails: () => void;
}

export const Toolbar: React.FC<ToolbarProps> = ({
  event,
  user,
  timer,
  eye,
  toggleOverlay,
  onOpenCluesDetails,
}) => {
  const [t] = useTranslation('common');
  const {
    state: { currentRoom, clues, showingClue, updated },
  } = useContext(VectibixContext);

  const cluesToFind = SCENES_DATA[currentRoom].clues;
  const foundClues: string[] = clues[currentRoom] || [];
  const timeOnClock = formatTime(timer / 1000);

  const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const previous: any = usePrevious({ showingClue, updated });

  useEffect(() => {
    if (previous && updated && updated !== previous.updated) {
      if (previous.showingClue) {
        const room: any = clues[currentRoom];
        if (!room || room[previous.showingClue] === undefined) {
          let clueIndex = -1;
          const data = cluesToFind.find((value: any, index: number) => {
            clueIndex = index;
            return value.title === previous.showingClue;
          });
          if (data && data.checked !== false) {
            Http.post('/update/resolved', {
              event,
              user,
              room: SCENES.indexOf(currentRoom),
              clue: clueIndex,
            }).then(() => {});
          }
        }
      }
    }
  }, [clues, previous, updated]);

  return (
    <div className="toolbar">
      <div className="button-wrapper">
        <div
          className="button"
          onClick={() => toggleOverlay('patient-folder')}
          role="button"
          tabIndex={0}
        >
          <div className="icon" style={{ backgroundImage: `url(${folder})` }} />
          <Textfit min={10} max={16} mode="single" className="button-title">
            {t('Patient Folder')}
          </Textfit>
        </div>
        <div
          className="button"
          onClick={() => toggleOverlay('inventory')}
          role="button"
          tabIndex={0}
        >
          <div className="icon" style={{ backgroundImage: `url(${box})` }} />
          <Textfit min={10} max={16} mode="single" className="button-title">
            {t('Objects')}
          </Textfit>
        </div>
      </div>
      {!eye && (
        <div className="clues-holder">
          {cluesToFind.map((clue) => {
            if (clue.checked !== false) {
              return (
                <img
                  key={clue.id}
                  src={foundClues.includes(clue.id) ? clueOn : clueOff}
                  alt={clue.id}
                />
              );
            }
            return '';
          })}
        </div>
      )}
      {eye && (
        <div className="eye">
          <span role="button" tabIndex={0} onClick={onOpenCluesDetails}>
            <img src={eyeSvg} alt="" />
          </span>
          <p>Raumansicht</p>
        </div>
      )}
      <div className="button-wrapper">
        <div
          className={classNames('button', {
            disabled:
              !showingClue ||
              !SCENES_DATA[currentRoom].clues.find((c) => c.id === showingClue)?.references,
          })}
          onClick={() =>
            showingClue &&
            SCENES_DATA[currentRoom].clues.find((c) => c.id === showingClue)?.references
              ? toggleOverlay('references')
              : null
          }
          role="button"
          tabIndex={0}
        >
          <div className="icon" style={{ backgroundImage: `url(${book})` }} />
          <Textfit min={10} max={16} mode="single" className="button-title">
            {t('References')}
          </Textfit>
        </div>
        <div className="button">
          <div className="icon" style={{ backgroundImage: `url(${clock})` }} />
          <Textfit min={10} max={16} mode="single" className="button-title">
            {timeOnClock.minutes}:{timeOnClock.seconds}
          </Textfit>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
