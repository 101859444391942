import React, { useState, useEffect, useCallback } from 'react';
import AdminClueOverlay from 'components/administrator/AdminClueOverlay';
import apartment from 'data/apartment.json';
import hospital from 'data/hospital.json';
import laboratory from 'data/laboratory.json';
import { useTranslation } from 'react-i18next';
import { CLUES } from 'Constants';
import scss from './room-mononitoring.module.scss';

interface RMProps {
  onRoomId: (e: number) => void;
  page: number;
  users: any[];
}

const RoomMonitoring: React.FC<RMProps> = ({ page, users, onRoomId }) => {
  const [t] = useTranslation('admin');
  const [roomId, setRoomId] = useState<number>(0);
  const [clueDetailsOpened, setClueDetailsOpened] = useState<boolean>(false);
  const [clueId, setClueId] = useState<string>('');
  const [cluesName, setCluesName] = useState<any[]>([]);
  const [cluesToFind, setCluesToFind] = useState<number>(5);

  const SCENES_DATA: any = {
    laboratory,
    hospital,
    apartment,
  };

  useEffect(() => {
    const rooms = Object.keys(SCENES_DATA);
    const room = page - 1;
    setRoomId(room);
    onRoomId(room);
    setCluesToFind(SCENES_DATA[rooms[room]].cluesToFind);
    const clues = SCENES_DATA[rooms[room]].clues.map((value: any, key: number) => {
      value.key = key;
      return value;
    });
    setCluesName(
      clues.filter((value: any, key: number) => {
        return value.checked !== false;
      })
    );
  }, [page]);

  const openClueDetails = useCallback((room: number, key: number) => {
    const rooms = Object.keys(SCENES_DATA);
    setClueId(SCENES_DATA[rooms[room]].clues[key].id);
    setClueDetailsOpened(true);
  }, []);

  return (
    <>
      <div className={scss.center}>
        {roomId === 0 && <h3>Die Teilnehmer befinden sich jetzt im Labor</h3>}
        {roomId === 1 && <h3>Die Teilnehmer befinden sich jetzt im Infusionsraum</h3>}
        {roomId === 2 && <h3>Die Teilnehmer befinden sich jetzt in der Wohnung</h3>}
      </div>
      <div className={scss.roomMonitoring}>
        <div>
          <div className="empty" />
          {users.map((user: any, index: number) => (
            <div key={index}>
              <span style={{ fontFamily: 'Jost-Medium', color: '#0097D0' }}>{user.name}</span>
            </div>
          ))}
        </div>

        {cluesName.map((value: any, key: number) => {
          const { title } = value;
          return (
            <div key={key}>
              <div className="header-cell">
                <i
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    openClueDetails(roomId, value.key);
                  }}
                >
                  <img src={`${CLUES[`${title}-right`][0]}`} alt="" />
                  <span>{`${t(title)}`}</span>
                </i>
              </div>
              {users.map((user: any, index: number) => (
                <div key={index} className={user.rooms[roomId].resolved[key] ? 'checked' : ''}>
                  <span />
                </div>
              ))}
            </div>
          );
        })}
      </div>
      {clueDetailsOpened && (
        <div className={scss.clueDetails}>
          <AdminClueOverlay
            id={clueId}
            step={0}
            closeOverlay={() => {
              setClueDetailsOpened(false);
            }}
          />
        </div>
      )}
    </>
  );
};

export default RoomMonitoring;
