import React, { useCallback, useState, useRef, useEffect } from 'react';
import Typing from 'react-typing-animation';
import robot from 'assets/svg/robot.svg';
import scss from 'components/speech/speech.module.scss';
import play from 'assets/svg/play.svg';
import concept from 'assets/img/speech/concept.jpg';
import background from 'assets/img/background.jpg';
import PatientFolder from 'components/patient-folder/PatientFolder';
import Overlay from 'components/overlay/Overlay';
// import Animation from 'components/speech/Animation';

interface IntroProps {
  children: React.ReactElement;
  isAdmin: boolean;
  IO: any;
  reset: boolean;
  onGameReset: () => void;
}
export const Speech: React.FC<IntroProps> = ({ children, isAdmin, IO, reset, onGameReset }) => {
  const [end, setEnd] = useState(false);
  const [typingStep, setTypingStep] = useState(1);
  const [gameStarted, setGameStarted] = useState<boolean>(false);
  const [patientFolderOpened, setPatientFolderOpened] = useState<boolean>(false);
  // // const [animationDone, setAnimationDone] = useState<boolean>(false);
  const robotElement = useRef<HTMLDivElement>(null);
  let id: ReturnType<typeof setTimeout>;

  const resetSpeech = () => {
    setEnd(false);
    setTypingStep(1);
    setGameStarted(false);
    setPatientFolderOpened(false);
  };

  useEffect(() => {
    resetSpeech();
  }, []);

  useEffect(() => {
    if (reset === true && !gameStarted) {
      onGameReset();
    }
  }, [reset, gameStarted]);

  useEffect(() => {
    if (gameStarted) {
      clearTimeout(id);
      setEnd(true);
    }
  }, [gameStarted]);

  useEffect(() => {
    let timeId: any;
    if (!isAdmin) {
      IO.on('users', (data: any) => {
        if (data === 'start-game') {
          setGameStarted(true);
          timeId = setTimeout(() => {
            IO.emit('step-in', 'START_GAME');
            console.log('step START GAME');
            clearTimeout(timeId);
          }, 300);
        }
      });

      IO.on('open-folder', (data: string) => {
        setPatientFolderOpened(true);
        IO.emit('step-in', 'OPEN_FOLDER');
      });

      IO.on('close-folder', (data: string) => {
        setPatientFolderOpened(false);
      });
    }
    return () => {
      clearTimeout(timeId);
    };
  }, [IO, isAdmin]);

  const onFinishedTyping = useCallback(
    (step: number = 0, time: number = 20000) => {
      clearTimeout(id);
      id = setTimeout(() => {
        setTypingStep(step + 1);
        clearTimeout(id);
      }, time);
    },
    [robotElement]
  );

  /*
  const onAnimationDone = useCallback(() => {
    setAnimationDone(true);
  }, []);

  const renderAnimation = (): React.ReactElement => {
    return (
      <>
        <Animation onAnimationDone={onAnimationDone} />
      </>
    );
  };
  */

  const renderIntro = (): React.ReactElement => {
    return (
      <>
        {/**/}
        {patientFolderOpened && (
          <div className={scss.patientFolder}>
            <Overlay closeOverlay={() => {}}>
              <PatientFolder />
            </Overlay>
          </div>
        )}
        <div className={`${scss.intro} fadein`} style={{ backgroundImage: `url(${background})` }}>
          <div>
            <div />
            <div />
          </div>
          <div>
            <div />
            <div>
              {typingStep === 1 && (
                <Typing
                  className="typing-container"
                  speed={50}
                  onFinishedTyping={() => {
                    onFinishedTyping(1, 3000);
                  }}
                >
                  <p>
                    <span className="text">Willkommen im Vtopia Medical Training Center.</span>
                  </p>
                </Typing>
              )}
              {typingStep === 2 && (
                <Typing
                  className="typing-container"
                  speed={50}
                  onFinishedTyping={() => {
                    onFinishedTyping(2, 3000);
                  }}
                >
                  <p>
                    <span className="text">
                      Lassen Sie mich Ihnen das Konzept unseres heutigen Fortbildungsprogramms
                      vorstellen:
                    </span>
                  </p>
                </Typing>
              )}
              {typingStep === 3 && (
                <Typing
                  className="typing-container"
                  speed={50}
                  onFinishedTyping={() => {
                    onFinishedTyping(3, 6000);
                  }}
                >
                  <p>
                    <span className="text">
                      Wir werden nun gemeinsam in einen Patientenfall eintauchen. Sie sollen dabei
                      insgesamt drei Szenarien betrachten, welche in Verbindung mit unserem
                      Patientenfall stehen.
                    </span>
                  </p>
                </Typing>
              )}
              {typingStep === 4 && (
                <Typing
                  className="typing-container"
                  speed={50}
                  onFinishedTyping={() => {
                    onFinishedTyping(4, 4000);
                  }}
                >
                  <p>
                    <span className="text">
                      Vergleichen Sie dabei jeweils zwei ähnliche aber nicht identische Räume
                      miteinander.
                    </span>
                  </p>
                </Typing>
              )}
              {typingStep === 5 && (
                <Typing
                  className="typing-container"
                  speed={50}
                  onFinishedTyping={() => {
                    onFinishedTyping(5, 6000);
                  }}
                >
                  <p>
                    <span className="text">
                      Finden Sie die feinen Unterschiede zwischen beiden Räumen und prägen Sie sich
                      die Informationen ein. Nach jedem der drei Szenarien werden Sie einen
                      Fragebogen lösen müssen.
                    </span>
                  </p>
                </Typing>
              )}
              {typingStep === 6 && (
                <Typing className="typing-container" speed={50}>
                  <p>
                    <span className="text">Schauen wir uns nun die Patientenakte an.</span>
                  </p>
                </Typing>
              )}
            </div>
          </div>
          <div>
            <div>
              {typingStep < 6 && (
                <button
                  type="button"
                  className="bt-validate"
                  onClick={() => {
                    onFinishedTyping(typingStep, 0);
                  }}
                >
                  <img src={play} alt="" />
                </button>
              )}
            </div>
            <div>
              <div className="robot" style={{ backgroundImage: `url(${robot})` }} />
            </div>
          </div>
        </div>
        {typingStep >= 3 && (
          <img alt="" src={concept} className={`${scss.doubleScreen} double-screen-fade`} />
        )}
      </>
    );
  };

  return (
    (!end && /* (!animationDone && renderAnimation()) || */ renderIntro()) ||
    (gameStarted ? children : <></>)
  );
};
export default Speech;
